// FIXME: holdover from reporting-dashboard theme, remove if not needed
//@use '~normalize.css' as *;

@use 'colors' as *;
@use 'variables' as *;
@use 'typography' as *;
@use 'style' as *;
@use '../widgets/DatePicker/TimePicker.style.scss' as *;

@import '~@sumup/circuit-ui/styles.css';
@import '~@sumup/design-tokens/light.css';
@import url('https://rsms.me/inter/inter.css');
@import 'react-date-range/dist/styles.css';
@import 'react-date-range/dist/theme/default.css';

:root {
  --breakpoint-sm: 360px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 1024px;
  --breakpoint-xl: 1280px;
  --horizontal-margin-sm: 16px;
  --horizontal-margin-md: 32px;
  --horizontal-margin-lg: 40px;
  --horizontal-margin-xl: unset;
}

#__next {
  width: 100%;
}

// show sentry feedback button on the left
// so it does not overlay over drawers
#sentry-feedback {
  right: auto;
  left: var(--cui-spacings-mega);

  //prevent feedback modal from showing on mobile
  @media screen and (max-width: $md) {
    display: none;
  }
}
